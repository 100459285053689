import styled from "styled-components";

/*
  These styles are shared among ReleasesTable, LibraryTable, PlaylistTracksTable, TracksTable, & TrackTableItems.

  If you need to modify a shared style, ensure you want to make the same modification
  to all the shared components. Otherwise, override the style in the specific style
  file for the component.
*/

export const Spacer = styled.div`
  width: 0.75rem;
`;

export const TrackNo = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  width: 2.25rem;
  min-width: 2.25rem;
	&.active {
    font-size: 1rem;
    color: ${(props) => props.theme.colors.neutrals.primary.white};
  }
`;

export const ItemNumber = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  width: 2.25rem;
`;

export const Exclusive = styled.span`
  right: 0;
  top: 0;
  height: 0.875rem;
  padding: 0 0.063rem;
  font-size: 0.438rem;
  background: ${(props) => props.theme.colors.green[300].accent};
  width: 100%;
  color: ${(props) => props.theme.colors.black};
  position: absolute;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
`;

export const ReleaseName = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 0.688rem;
  line-height: 1rem;
  letter-spacing: 0.005em;

  color: ${(props) => props.theme.colors.neutrals.primary.white};

  span {
    font-weight: 400;
  }
`;

export const ArtworkAndControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.625rem;

  a.artwork {
    position: relative;
  }
`;

export const Wrapper = styled.div`
  .fade {
    opacity: 0.25;
    transition: opacity 0.2s ease-in-out;
  }

  .row {
    justify-content: flex-start;

    &:hover .fade {
      opacity: 1;
    }

    &.current {
      background: ${(props) =>
			props.theme.colors.neutrals.secondary.gray.active};
    }
  }

  .cell {
    padding-top: 0;
    padding-bottom: 0;

    a:hover {
      text-decoration: underline;
    }

    &.price {
      font-size: 0.875rem;
      font-weight: 700;
      color: ${(props) => props.theme.colors.white[1]}; 
    }
  }

  .controls {
    padding-left: 0;
    width: 7.5rem;
    min-width: 7.5rem;
  }

  .title {
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    .container {
      overflow: hidden;
      > div,
      > a > span {
        overflow: hidden;
      }
    }
  }

  .format {
    width: 8.5rem;
    min-width: 8.5rem;
  }

  .price {
    width: 4.5rem;
    min-width: 4.5rem;
  }

	.cart-actions {
    width: 7.5rem;
    min-width: 7.5rem;
    display: flex;
    justify-content: flex-end;
  }

	.card {
    width: 6.25rem;
    min-width: 6.25rem;
    display: flex;
    justify-content: flex-end;
  }

  &.numbers {
    .controls {
      width: 10.3125rem;
      min-width: 10.3125rem;
    }
  }
`;

export const LoaderWrapper = styled.div`
  width: 30.2%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.5rem;
  }
`;
