import { TableTypes } from "@bptypes/tables";
import DownloadAllButton from "@components/interaction/DownloadButtons/DownloadAllButton";
import { PageLoader } from "@components/loaders";
import { FinalRedownloadConfirmationModal } from "@components/modals/FinalRedownloadConfirmationModal";
import { filenamePresets } from "@lib/constants/audio-format";
import { useCollectionActions, useCollectionState } from "@lib/context/collection";
import { usePlayerActions, usePlayerState } from "@lib/context/player";
import { useSessionContext } from "@lib/context/session";
import { cls } from "@lib/css";
import { useStrictDroppable } from "@lib/hooks/useStrictDroppable";
import { getMyAccountRequest } from "@lib/network/my";
import { checkIfUserHasSubscription, updateSelectedTracks } from "@lib/utils";
import { CartItem } from "@models/Cart";
import { Audioformat } from "@models/audio-format";
import { Track } from "@models/track";
import { pushProductClickEvent, pushProductImpressionEvent } from "@utils/dataLayer";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "next-i18next";
import { Fragment, MouseEventHandler, useCallback, useEffect, useState } from "react";
import {
	DragDropContext,
	Draggable,
	Droppable,
	OnDragEndResponder,
} from "react-beautiful-dnd";
import { ListHeading, Wrapper } from "../Lists.shared.style";
import { TrackListItem } from "./TrackListItems/TrackListItem";

interface Props {
	isLoading?: boolean;
	tracks?: Track[];
	cartTracks?: CartItem<Track>[];
	showNumbers?: boolean;
	showArtwork?: boolean;
	forceHover?: boolean;
	isPanelList?: boolean;
	direction?: "row" | "column";
	itemCartActions?: (cartItem: CartItem<Track>) => JSX.Element;
	formatSelector?: (cartItem: CartItem<Track>) => JSX.Element;
	selectedItems?: number[];
	onItemSelectionChange?: (itemId: number, selected: boolean) => void;
	audioFormats?: Record<number, Audioformat>;
	isCollection?: boolean;
	isDownloads?: boolean;
	isAddTrackModal?: boolean;
	isPaginated?: boolean;
	isThankYou?: boolean;
	chartAddTrackLoading?: number[];
	chartAddedTracks?: number[];
	chartAddTrack?: (trackId: number) => void;
	trackListType?: TableTypes;
	chartDeleteTrack?: (trackId: number) => void;
	draggable?: boolean;
	onDragEnd?: OnDragEndResponder;
	dataTestId?: string;
	location?: string;
	page?: number;
	perPage?: number;
	overallEncodingStatus?: string;
	loadingItems?: number[];
}

const TracksList: React.FC<Props> = ({
	isLoading,
	tracks = [],
	cartTracks,
	showNumbers,
	showArtwork,
	forceHover,
	isPanelList,
	direction = "row",
	itemCartActions,
	selectedItems,
	onItemSelectionChange,
	audioFormats = {},
	isCollection = false,
	isDownloads = false,
	isAddTrackModal = false,
	isPaginated = false,
	isThankYou = false,
	chartAddTrackLoading = [],
	chartAddedTracks = [],
	chartAddTrack,
	trackListType,
	chartDeleteTrack,
	draggable,
	onDragEnd,
	dataTestId = "tracks-list-item",
	location,
	page = 1,
	perPage = 0,
	overallEncodingStatus,
	formatSelector,
	loadingItems,
}) => {
	const [finalRedownloadModalCallback, setFinalRedownloadModalCallback] = useState<(() => MouseEventHandler) | null>(null);
	const [controls, setControls] = useState<number | undefined>();
	const [filenamePattern, setFilenamePattern] = useState("");
	const [selectedTracks, setSelectedTracks] = useState<Track[]>([]);
	const [areTracksDownloaded, setAreTracksDownloaded] = useState(false);
	const { currentTrack } = usePlayerState();
	const { getAccessToken, getIsSessionValid, session } = useSessionContext();
	const { playTrack, addTracks } = usePlayerActions();
	const { playlist_queue: queueTracks } = useCollectionState();
	const { addTracksToPlaylist } = useCollectionActions();
	const accessToken = getAccessToken();
	const isSessionValid = getIsSessionValid();
	const [dndEnabled] = useStrictDroppable(false);
	const isSubscribed = checkIfUserHasSubscription(session?.introspect);
	const { t } = useTranslation("translation");
	const exclusive = `${t("Exclusive")}`;

	useEffect(() => {
		(async () => {
			// This request is only needed if the component is being used in the downloads view,
			// as we need to fetch the filenamePattern that the user has set.
			if (!isDownloads || !isSessionValid) return;
			const account = (await getMyAccountRequest({ accessToken })).data;
			setFilenamePattern(
				account.preferences?.filename_convention || filenamePresets[1],
			);
		})();
	}, [accessToken, isDownloads]);

	const downloadAllTracks = tracks.filter((track) => !track.pre_order);

	const filteredTracks = cartTracks ?
		cartTracks
			.filter((t) => t.item !== undefined)
			.map((c) => {
				c.item.cart_item_data = c;
				c.item.purchase_type_id = c.purchase_type_id;
				return c.item;
			}) :
		tracks;

	useEffect(() => {
		// Disabled Thank You page Download All button if tracks have been downloaded
		if (isThankYou && downloadAllTracks.length > 0) {
			const downloadedTracks = downloadAllTracks.filter((track) => {
				return track.received_status === true;
			});
			if (downloadedTracks.length === downloadAllTracks.length) setAreTracksDownloaded(true);
		}
	}, [downloadAllTracks, isThankYou]);

	const getProductEventData = useCallback(
		(track: Track, index: number) => ({
			id: track.id.toString(),
			name: track.name,
			position: index + 1,
			label: track?.release?.label?.name || null,
			list: location || null,
			artists: track?.artists?.map((a) => a.name).join(", ") || null,
			remixers: track?.remixers?.map((r) => r.name).join(", ") || null,
			genres: track?.genre?.name || null,
			subGenres: track?.sub_genre?.name || null,
			preOrder: track.pre_order,
			type: "product",
			category: "Tracks",
			variant: "track",
			price: track?.price?.value || null,
			imageUrl: track?.release?.image?.uri,
		}),
		[location],
	);

	useEffect(() => {
		if (!isEmpty(filteredTracks)) {
			pushProductImpressionEvent({
				ecommerce: {
					currencyCode: filteredTracks[0]?.price?.code || "",
					impressions: filteredTracks?.map(getProductEventData),
				},
			});
		}
	}, []);

	const handleTrackClick = (track: Track, index: number) => {
		pushProductClickEvent({
			ecommerce: {
				currencyCode: track?.price?.code || "",
				click: {
					actionField: {
						list: location || "",
					},
					products: [getProductEventData(track, index)],
				},
			},
		});
	};

	const handleSelectTrack = (
		e: React.ChangeEvent<HTMLInputElement>,
		track: Track,
	) => {
		const isChecked = e.target.checked;
		const updatedTracks = updateSelectedTracks(
			{ isChecked, isCollection, track, queueTracks, selectedTracks },
		);
		if (isCollection) {
			addTracksToPlaylist(updatedTracks);
		}
		setSelectedTracks(updatedTracks);
	};

	const isCartList = cartTracks !== undefined;

	const handlePlayTrackClick = (track: Track) => {
		playTrack(track);
		setTimeout(() => addTracks([track]), 100);
	};

	const inheritedTrackProps = {
		t,
		isSubscribed,
		isPaginated,
		perPage,
		page,
		trackListType,
		location,
		dataTestId,
		chartDeleteTrack,
		currentTrack,
		controls,
		setControls,
		showArtwork,
		forceHover,
		isCartList,
		isAddTrackModal,
		exclusive,
		handlePlayTrackClick,
		isCollection,
		handleSelectTrack,
		queueTracks,
		showNumbers,
		filteredTracks,
		onItemSelectionChange,
		selectedItems,
		handleTrackClick,
		audioFormats,
		setFinalRedownloadModalCallback,
		itemCartActions,
		isDownloads,
		filenamePattern,
		chartAddTrack,
		chartAddTrackLoading,
		chartAddedTracks,
		formatSelector,
	};

	const hasSelectedTracks = selectedTracks.length > 0;

	return (
		<>
			<FinalRedownloadConfirmationModal
				show={!!finalRedownloadModalCallback}
				onClose={() => { setFinalRedownloadModalCallback(null); }}
				onConfirmReDownload={() => {
					if (finalRedownloadModalCallback) {
						finalRedownloadModalCallback();
						setFinalRedownloadModalCallback(null);
					}
				}}
			/>
			{isDownloads && (
				<DownloadAllButton
					tracks={hasSelectedTracks ? selectedTracks : downloadAllTracks}
					disabled={downloadAllTracks.length === 0 || isLoading || areTracksDownloaded}
					filenamePattern={filenamePattern}
					fullButton={true}
					overallEncodingStatus={hasSelectedTracks ? undefined : overallEncodingStatus}
				/>
			)}
			{
				isCollection && !isAddTrackModal ?
						(
							<ListHeading>
								<div>{t("TrackName")}</div>
								<div>{t("ArtistsRemixers")}</div>
							</ListHeading>
						) :
					null
			}
			{isLoading ?
					(
						<PageLoader />
					) :
					(
						<Wrapper
							className={cls(direction, isPanelList ? "panel-list" : undefined)}
						>
							{draggable && onDragEnd ?
									(
										<DragDropContext onDragEnd={onDragEnd}>
											{dndEnabled && (
												<Droppable droppableId="chart-tracks-droppable">
													{(provided) => (
														<div
															className="droppable"
															ref={provided.innerRef}
															{...provided.droppableProps}
														>
															{filteredTracks.map((track, index) => (
																<Draggable
																	draggableId={`chart-track-${track.id}`}
																	index={index}
																	key={`chart-track-${track.id}`}
																>
																	{(innerProvided) => (
																		<div
																			ref={innerProvided.innerRef}
																			{...innerProvided.draggableProps}
																		>
																			<TrackListItem
																				track={track}
																				index={index}
																				dragHandleProps={
																					innerProvided.dragHandleProps || undefined
																				}
																				{...inheritedTrackProps}
																			/>
																		</div>
																	)}
																</Draggable>
															))}
															{provided.placeholder}
														</div>
													)}
												</Droppable>
											)}
										</DragDropContext>
									) :
									(
										filteredTracks.map((track, index) => {
											const isLoading = track.cart_item_data && loadingItems?.includes(track.cart_item_data?.id);
											return (
												<Fragment key={`list-track-${track.id}`}>
													<TrackListItem track={track} index={index} isLoading={isLoading} {...inheritedTrackProps} />
												</Fragment>
											);
										})
									)}
						</Wrapper>
					)}
		</>
	);
};

export default TracksList;
