export const SYMBOL_MAP: Record<string, string> = {
	AUD: "$",
	BRL: "$",
	EUR: "€",
	GBP: "£",
	MXN: "$",
	USD: "$",
};

export const DEFAULT_CURRENCY = "USD";
export const DEFAULT_COUNTRY = "US";
